.navbar-custom {
    margin: 15px 0px 0px 15px;
    background-color: #009B78;
    border-radius: 10px;
    z-index: 999;
}

.scrolly {
    
    max-height: var(--bs-scroll-height, calc(88vh - 156px));
    overflow-y: auto;
}

.navbar-brand {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1px;
    margin: 1px 0px 10px;
}

.navbar-toggler-icon{
    color: white;
}

.navbar-collapse {
    padding-left: 0.75rem;
}

.custom-nav-item {
    
    width: 215px;
}


/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #008062 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  *::-webkit-scrollbar-track {
    background: #d0ede6;
    border-radius: 6px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #008062;
    border-radius: 10px;
    border: 1px solid #d0ede6;
  }

.logo {
    height: 32px;
}

.profile-image {
    margin-left: 5px;
    width: 50px;
    height: 50px;
}
.profile-text {
    margin-left: 10%;
    width: 100%;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}

.nav-item {
    display: flex;
    align-items: center;
}

.nav-item a{
    width: 100%;
    height: 50px;
    margin-top: 5px;
}

.nav-link {
    align-items: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    border-radius: 10px;
    width: 100%;
    margin-left: 4px;
    
}

.nav-link:hover {
    background-color: rgba(255, 255, 255, 0.500);
    color: white;

}
.nav-item svg {
    font-size: 22px;
    font-weight: 500;
    margin-right: 14px;
    color: white;
}
.nav-item span{
    list-style: none;
    justify-content: center;
    color: white;
}
.disabled svg, .disabled span{
    color: rgba(0,0,0,.3)
}

.navbar .container-fluid, 
.navbar-expand-lg .navbar-collapse, 
.navbar-expand-lg .navbar-nav{
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 991px) {
    .navbar {
        margin-left: 4vw;
        width: 90vw;
        align-items: center;
    }
    .navbar-collapse {
        flex-direction: column;
    }
    .navbar-brand {
        width: 90vw;
        justify-content: space-between;
        margin: 0px 10px 0px 10px;
    }
}

@media (min-width: 992px) {
    .navbar,
    .navbar-collapse {
        flex-direction: column;
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: column;
    }
    .navbar {
        width: 242px;
        height: calc(100vh - 28px);
        align-items: flex-start;
    }
    .navbar-brand {
        padding-bottom: 0;
    }
    .logout{
        position: absolute;
        bottom: 1%;
    }
}

/* @media (max-height: 1380px) {
    .scrolly {
        max-height: calc(100vh - 148px);
    }
     @media (max-height: 1220px){
        .scrolly {
            max-height: var(--bs-scroll-height, 66vh);
        }
    } 
} */

@media (max-height: 780px) and (min-width: 992px) {
    .navbar {
        height: calc(100vh - 28px);
        /*height: 750px;*/
    }    
}

