.rdt_TableHeadRow{
    font-size: 16px;
    font-weight: 700;
    height: 70px;
  }

  .rdt_TableRow {
    
    border-bottom-style: hidden !important;
    height: 60px;
  }

  .rdt_TableCell {
    font-size: 16px;
    font-weight: 400;
  }

  .rdt_TableCell:first-child input[type=checkbox] {
    color: #009B78;
  }

  .rdt_TableBody {
    height: 600px;
  }