.bg-spread {
    background-image: url(../assets/background-left-vector.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left top;
    width: 100vw;
    height: 100vh;
    margin-left: -1rem !important;
}
.circulo1 {
    position: absolute;
    width: 192px;
    height: 192px;
    top: 40%;
    left: 460px;
    background: rgba(33, 168, 138, 0.21);
    z-index: 1;
}
.circulo2 {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 26%;
    left: 23%;
    background: rgba(33, 168, 138, 0.21);
    z-index: 1;
}
.circulo3 {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 25%;
    left: 17%;
    background: rgba(33, 168, 138, 0.21);
    z-index: 1;
}
.telefono-icon {
    background-image: url(../assets/icon-app-mobile.svg);
    background-repeat: no-repeat;
    background-position-y:center;
    position: absolute;
    left: 12%;
    right: 12%;
    top: 0%;
    bottom: 0%;
    z-index: 10;
}
.circulo4{
    position: absolute;
    width: 50px;
    height: 57px;
    left: 2%;
    top: 80%;
    background: rgba(255, 255, 255, 0.1);
    z-index: 10;
}
.circulo5{
    position: absolute;
    width: 100px;
    height: 113px;
    left: 4%;
    bottom: 5%;
    background: rgba(255, 255, 255, 0.15);
    z-index: 10;
}
.circulo6{
    position: absolute;
    width: 100px; 
    height: 100px; 
    left: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.15);
    z-index: 10;
    -moz-border-radius: 0 100% 0 0;
    -webkit-border-radius: 0 100% 0 0;
    border-radius: 0 100% 0 0;
}
.texto-spread{
    position: absolute;
    width: 519px;
    height: 186px;
    left: 3%;
    bottom: 2%;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 82px;
    text-align: center;
    color: #FFFFFF;
}
.border-radius{
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

@media (max-height: 780px) {
    .texto-spread {
        display: none;
    }
}

@media (max-width: 900px) {
    .telefono-icon {
        display: none;
    }
}