.DivContainer {
    border: 1px solid rgba(255, 255, 255, 0.418);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: calc(100vh - 156px);
}

.FormContainer {
    max-height: calc(100vh - 364px);
    overflow-y: auto;
}

.title {
    color: #272726;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-top: 18px;
    margin-bottom: 18px;
}

.SegTitle {
    color: #787777;
    margin-left: 15px;
    margin-top: 20px;
    margin-bottom: -10px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}



.InputPrincipal {
    height: 50px;
    background-color: #AFAFAF1A;
    background: rgba(175, 175, 175, 0.1);
    border-radius: 10px;
    border: none;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}

Input {
    margin-top: 10px;
}



.InputPrincipal:focus, .InputPrincipal:hover, .InputPrincipal:active {
    border: 1px solid #009B78;
}

.containerBtn {
    padding-top: 40px;
    justify-content: center;
    padding-bottom: 20px;
}

.BtnBuscar {
    height: 50px;
    background: #009b77;
    border-radius: 10px;
    border: none;
}

.TxtBtnBuscar {
    font-weight: 550;
    font-size: 15px;
    font-family: 'Nunito';
    line-height: 22px;
    margin-top: -30px;
}

.BtnBuscar:hover {
    background-color: #30c7a4;
    border-color: #009b77;
}

.BtnBuscar:active,
.BtnBuscar:focus {
    background-color: #009B78;
}

.iconBuscar {
    font-size: 25px;
    color: #fff;
    line-height: 22px;
    margin-right: 10px;
    vertical-align: middle;
}

.DivInputFecha, .DivInputFecha span{
    border: 1px solid black;
    height: 50px;
    background: rgba(175, 175, 175, 0.1);
    border-radius: 10px;
    border: none;
}

.InputPrincipal::placeholder {
    font-size: 14px;
}

.DivInputFecha span {
    height: 50px;
}

.InputPrincipalFecha{
    height: 50px;
    background: rgba(175, 175, 175, 0);
    border-radius: 10px;
    border: none;    
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #787777;
    margin-top: 0px;
}

.InputPrincipalFecha::placeholder {
    height: 20px;
    color: #787777;
    font-size: 14px;
}

input[type="date"]::-webkit-calendar-picker-indicator {   
    border: 0px;
    filter: invert(60%);
    margin-right: -.35em;
  }

.InputPrincipalFecha:focus,
.InputPrincipalFecha:active {
    border: 1px solid #009B78;
}