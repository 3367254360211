.chips-input-modal fieldset {
    overscroll-behavior-x: auto;
    overflow-y: auto;
    resize: none;
    flex: 0 0 auto;
    flex-wrap: nowrap;
}


.chips-input {
    /* border: 0;
    border-radius: 0px;
    font-family: Nunito;
    font-style: normal;
    color: #787777;*/
    /*height: 50px;
    width: 100%; 
    overflow-y: hidden;*/
    
    /*max-height: 50px;*/
    padding-top: 5px;
    resize: none;
    /*flex-wrap: nowrap !important;*/
    display: flex;    
    overflow-x: auto;
    flex-wrap: nowrap;
}



.chips-input input{
    border: 0;
    flex-wrap: nowrap;
}

.chips-input fieldset {
    overscroll-behavior-x: auto;
    overflow-y: hidden;
    max-height: 50px;
    resize: none;
    flex: 0 0 auto;
    width: 80%;
    flex-wrap: nowrap;
}

.chips-input .MuiInputBase-root {
    flex-wrap: nowrap;
}

.MuiInputBase-root {
    flex-wrap: nowrap;
}