
.custom-item {
    border-block-end: 3px solid #fff;
    color: #787777;
    width: 288px;
}

.custom-item:hover,.custom-link:hover, .custom-item:active, .custom-item:focus{
    background: rgba(33, 168, 138, 0.21);
    transition: background-color .8s;
    border-block-end: 3px solid #009B78;
}
p.custom-text{
    text-align: center;
    margin-top: 10px;
    color: #787777;
    font-weight: 700;
}
p.custom-text:hover{
    color: #009b78;
    transition: color .8s;
}

.input-group-lg > .form-control, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .btn {
    height: 49px;
}