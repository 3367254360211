.searcheractioner {
    margin-top: 0;
    font-size: 20px;
    width: 40px;
    height: 50px;
    border-radius: 10px;
    z-index: 999;
    background: #F7F7F7;
    color: #787777;
    border: none;
  }

#searcher, #searcher:focus, #searcher:active, #searcher.active, #searcher:hover {
    width: 0;
    transition: 0.3s;
    border-radius: 10px;
    margin-top: 0;
    font-size: 18px;
    height: 50px;
    border: none;
    background: #F7F7F7;
    margin-right: -40px;
  }
  
  #searcher.exp-search-show {
    width: 61%;
  }

  .dropdown-menu {
    background: #fff;
    border: 1px solid #009B78;
    width: 97%;
    border-radius: 10px;
  }
  .dropdown-item {
    color: #787777;
  }

  .dropdown-item:hover {
    color: #000;
    background: rgba(33, 168, 138, 0.21);
  }