.dropzone {
    padding-top: 20px;
    border: 2px dashed #3AB297;
    border-radius: 10px;
    background-color: #E9F7F4;
    color: #787777;
    width: 60%;
    min-width: 195px;
}
  
.accept {
    border-color: #107c10 !important;
}

.reject {
    border-color: #d83b01 !important;
}

@media (max-width: 991px) {
    .dropzone {
        padding: 0px;
        margin: 0px;
        border: 0px;
        background-color: #fff;
        color: #fff;
        width: 30%;
    }
    .accept .reject {
        border: 0px;
    }
    .text-dropzone {
        display: none;
    }
    .btn-template {
        margin-top: 0px;
        padding-top: 15px;
    }
    .dropzone svg {
        display: none;
    }
}