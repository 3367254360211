.card-style-one{
     background: rgba(235, 128, 20, 0.774);
     align-items: center;
     height: 360px;
     width: 371px;
     border: none;
     border-radius: 10px;
     box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
     margin: 6px -20px 15px 30px;
 }
 
 .card-header-one {
     width: 100%;
     height: 519px;
     background-image: none;
 }
 
 .card-body-btn-tools-f .btn {
     width: 200px;
     height: 60px;
     background-color: #f7f3f3;
     border: none;
     border-radius: 10px;
     font-family: 'Nunito';
     font-style: normal;
     font-weight: 600;
     font-size: 18px;
     line-height: 43px;
     color: rgb(255, 145, 20);
     align-items: center;
 }
 
 .card-text-o {
     font-family: 'Roboto';
     font-style: normal;
     font-weight: 600;
     font-size: 21px;
     line-height: 25px;
     color: white;
     margin-top: 20px;
 }
 
 .HeaderContainer-o{
     width: 100%;
 }
 
 .text-center{
     text-align: left;
 }

 .text-instruction-o{
    position: static;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    font-size: 16px;
    line-height: 25px;
    color: white;
    width: 100%;
    margin: 0px 5px;
}

.text-a-o{
    font-size: 20px;
    text-decoration: none;
    font-weight: bold;
    color: white;
    text-align: center;
}

.text-p{
    font-size: 16px;
    font-weight: 300;
    /* border: 1px solid black; */
}

.text-a:hover{
    text-decoration: none;
    color: white;
}