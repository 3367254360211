.Container{
    width: 350px;
    height: 60%;
    position: absolute;
    margin-top: -80px;
}


.infoBox-style{
     position: absolute;
     display: inline-block;
     align-items: center;
     height: 510px;

     border: none;
     border-radius: 10px;
     margin: 6px -20px 15px 30px;
     background: #FFFFFF;
     box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
     margin-top: 50px;
 }
 
 .card-header-tools {
     /*width: 100%;*/
     height: 519px;
     background-image: none;
 }
  
 .card-text-tools {
     font-family: 'Roboto';
     font-style: normal;
     font-weight: 600;
     font-size: 21px;
     line-height: 25px;
     color: #296DF6;
     margin-top: 40px;
 }
 
 .RowBox-Header{
     /*width: 100%;*/
     height: 205px;
     position: relative;
     margin-top: 15px;
 }
 .RowBox-Header-2{
    width: 100%;
    height: 95px;
    position: relative;
}
.RowBox-Header-3{
    width: 100%;
    height: 80px;
    position: relative;
}

 .titleInfo{
    position: relative;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    margin-top: 5px;
    color: #272726;
 }

 .subTitleInfo{
    position: absolute;
    width: 100px;
    margin-top: -10px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #272726;
 }

 .bodyInfo{
    position: absolute;
    width: 288px;
    margin-top: 20px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: #787777; 
 }

 .IconPonit{
    color: #009B78;
    position: relative;
    float: left;
    margin-top: 3px;
 }