.card-style-tools{
    background: #296df658;
    align-items: center;
    height: 480px;
    width: 371px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
    margin: 6px -20px 15px 30px;
}

.card-header-tools {
    width: 100%;
    height: 519px;
    background-image: none;
}

.card-body-tools .btn {
    width: 200px;
    height: 60px;
    background: #1976D2;
    border: none;
    border-radius: 10px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 43px;
    color: white;
    align-items: center;
    
}

.card-body-tools .btn:hover {
    background: #1976D2;
    color: white;
 }
 
 .card-body-tools .btn:focus,
 .card-body-tools .btn:active {
    background-color: #1976d26d;
    cursor: pointer;
    transition: .3s;
 }
 
 .card-body-tools .btn:visited {
    background-color: #1976D2;
 }

.card-text-tools {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
    color: #296DF6;
    margin-top: 20px;
}

.card-img{
    position: absolute;
    width: 125px;
    height: 106px;
    margin-left: 240px;
}

.HeaderContainer{
    width: 80%;
}

.text-center{
    text-align: center;
}

.text-instruction{
    position: static;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    font-size: 16px;
    line-height: 25px;
    color: #000000;
    margin-top: -5px;
    width: 100%;
}

.text-a{
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    color: #000000;
}

.text-p{
    font-size: 16px;
    font-weight: 300;
    width: 100%;
    /* border: 1px solid black; */
}

.text-a:hover{
    text-decoration: none;
    color: green;
}
