.card-style-tools-r{
    background: rgba(128, 128, 128, 0.198);
    align-items: center;
    height: 360px;
    width: 391px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
    margin: 6px -20px 15px 30px;
}

.card-header-tools-r {
    width: 100%;
    height: 519px;
    background-image: none;
}

.card-body-tools-r .btn {
    width: 220px;
    height: 60px;
    background-color:#000000;
    border: none;
    border-radius: 10px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 43px;
    color: white;
    align-items: center;
}

.card-text-tools-r {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 20px;
    color: #000000;
    margin-top: 45px;
}

.card-img-r{
    position: static;
    width: 125px;
    height: 44px;
    margin-top: -83px;
    margin-left: 250px;
    background-image: url('../../../../public/img/logo-ripley.png');
}

.HeaderContainer{
    width: 78%;
}

.text-center{
    text-align: left;
}

.text-instruction{
    position: static;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    font-size: 16px;
    line-height: 25px;
    color: #000000;
    margin-top: -5px;
    width: 100%;
}

.text-a{
    font-size: 20px;
    text-decoration: none;
    font-weight: bold;
    color: #000000;
}

.text-p{
    font-size: 16px;
    font-weight: 300;
    width: 100%;
    /* border: 1px solid black; */
}

.text-a:hover{
    text-decoration: none;
    color: green;
}