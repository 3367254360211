.subheader-item {
    min-width: 150px;
    max-width: 288px;
    width: 100%;
}

.subheader-link {
    background-color: #fff;
    width: 100%;
    border: 1px;
    height: 60px;
    border-radius: 10px 10px 0px 0px;
    border: rgb(9, 139, 12);
}

.subheader-link > p {
    margin-top: 15px;
    color: #787777;
    font-weight: bold;
}

.subheader-link.active > p {
    color: #009b78;
}

.subheader-link.active, .subheader-link:hover, .subheader-link:focus {
    background-color: rgba(33,168,138,.21);
    border: #098b0c;
    color: #009b78;
}

.subheader-link.active {
    border-block-end: 2px solid #009b78;
}

.subheader-item a{
    color: #787777;
}


