.modal-content-custom {
    width: 1200px;
}
.modal-dialog-custom {
    max-width: 1200px;
}

.input-custom {
    height: 60px;
    font-size: 15px;
    
}
.input-custom input {
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;
}

.input-custom-observation {
    height: 50px;
    border-radius: 10px;
    font-size: 15px;

}

.dropdown-item {
    font-size: 15px;
}

.input-custom-filter input {
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    height: 50px;
}