.Modal{
     
     border-radius: 10px;
} 

.ModalHeader{
     background-color: #ffff;
     align-items: center;
     border: none;
     height: 180px;
}

.RowTitle{
     width: 100%;
     display: flex;
     justify-content: center;
     /* border: 1px solid black; */
     /* height: 100px; */
}

.IconClose{
     position: absolute;
     width: 95%;
     font-size: 40px;
     /* bottom: 25%; */
     top: 5px;
     cursor: pointer;
}

.ModalTitle{
     color: #272726;
     width: 100%;
     height: 88px;
     font-family: 'Nunito';
     font-style: normal;
     font-weight: 700;
     font-size: 27px;
     line-height: 44px;
     text-align: center;
     position: initial;
}

.ModalBody{
     background-color: #ffff;
     color: black;
}

.ModalFooter{
     background-color: #ffff;
     height: 265px;
}

.RowBtnMail{
     width: 95%;
     margin: -5px;
}

.BtnMail{
     /* border: 1px solid black; */
     display: flex;
     justify-content: left;
     
}

.Mail{
     border: 1px solid black;
     border-bottom: #009B78;
     font-size: 30px;
     background-color: white;
     color: #009B78;
     border: none;
     height: 70%;
     margin-top: 10px;
     align-content: center;
     
     /* border: none; */
}
.DivInputMail{
     /* border: 1px solid black; */
     width: 78%;
     height: 60%;
     margin-top: 10px;
}
.LabelHelp{
     font-size: 12px;
     position: static;
     text-align: center;
     color: black;
     /* margin: -50px -70px; */
}

.InputMail{
     border: 1px solid #009B78;
     width: 80%;
     border-radius: 10px;
     border-color: #009B78;
     box-decoration-break: none;
}


.TextBtnMail{
     font-size: 15px;
     margin-top: -10px;
}

/* .TextBtn:active{
     margin-top: 10px;
     margin: -14px -10px;
     color: white;
} */

.IconMail{
     margin-top: -10px;
}

.Mail:hover{
     background-color: #009B78;
     color: white;
     border-radius: 10px;
     max-height: 70%;
}

.Mail:active{
     background-color: white;
     color: white;
     border-radius: 10px;
     max-height: 70%;
}

.Mail:focus{
     background-color: #009b7789;
     color: white;
     border-radius: 10px;
     max-height: 70%;
}

.CloseMail{
     font-size: 20px;
     background-color: white;
     color: black;
     border: none;
}
.IconX{
     margin-top: 12px;
}

.CloseMail:hover{
     background-color: white;
     color: red;
     border-radius: 10px;
}

.CloseMail:focus{
     background-color: #009B78;
     color: white;
     border-radius: 10px;
     border: none;
}

/* .InputMail:active{
     outline: none;
}
.InputMail:focus{
     outline: none;
     border-color: white;
} */

.RowBtn{
     width: 100%;
     height: 115px;
     margin-top: 20px;
}

.ColBtn{
     display: flex;
     justify-content: center;
}

.BtnClose{
     width: 90%;
     height: 50px;
     border-radius: 10px;
     background: #FFFFFF;
     border: 1px solid #EA4335;
     border-radius: 10px;
     color: #EA4335;
}

.BtnClose:hover{
     background-color: #EA4335;
     color: white;
}

.BtnContinue{
     width: 90%;
     height: 50px;
     background: #009B78;
     border-radius: 10px;
}

.BtnContinue:hover, .BtnContinue:active{
     background-color: #009b77c3;
     color: white;
}

Label{
     font-family: 'Nunito';
     width: 100%;
     font-style: normal;
     font-weight: 400;
     font-size: 19px;
     line-height: 33px;
     color: #787777;
}

.DivUploadFile {
     /* position: absolute; */
     padding: 20px;
     width: 600px;
     background-color: white;
     border: #787777 4px dashed ;
     border-radius: 10px 10px;
     height: 80px;
     display: flex;
     justify-content: center;     
 }
 
 button.UploadFile {
     position: absolute;
     top: 0px;
     left: 0px;
     right: 0px;
     bottom: 0px;
     opacity: 0;
     height: 38%;
     width: 73%;
     margin-top: 70px;
     justify-content: right;
     margin: 60px 105px;
 }
 
 .UploadFile:focus {
  background-color: #009B78;
 }

 .textInput{
     font-size: 18px;
     width: 55%;
 }

 .IconUp{
     font-size: 25px;
     margin-top: 8px;
     color: #787777;
 }

 .ColExclamation{
     position: relative;
     display: flex;
     justify-content: left;
     width: 100%;
     font-size: 18px;
     color: #787777;
     margin: 10px 75px -10px;
 }

 .IconExclamation{
     font-size: 30px;
     margin: 2px;
 }
