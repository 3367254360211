.card-container {
    /* height: 300px;*/
    width: 500px; 
    background-color: transparent;
    margin-left: 40%;
    margin-top: 0em;
    z-index: 999;
}

.container-login{
    z-index:998;
    position: flex;
    top: 0;
    width: 100%;
}

.text-header{
    font-size: 28px;
    margin-bottom: 24px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 34px;
    line-height: 46px;
    color: #009B78;
}

.text-middle{
    color: black;
    text-align: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
}

.vertical-center{
    min-height: 100%;
    min-height: 100vh;

    display: flex;
    align-items: center;
}

@media (max-width: 900px) {
    .card-container {
        margin-left: 15%;
    }
}