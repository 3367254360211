.ContScroll{
     height: 630px;
	   width: 100%;
	   overflow-y: scroll;
     -webkit-scrollbar: black;
     background-color: white;
}

.ContScroll::-webkit-scrollbar {
     background: rgba(33, 168, 138, 0.21);
     border-radius: 10px;
   }

   /* .ContScroll::-webkit-scrollbar-track {
     box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
   } */

   .ContScroll::-webkit-scrollbar-thumb {
     outline: -10px;
     background: #009B78;
     border-radius: 10px;
     scrollbar-width: 10px;
   }