/* .titleExpedition{
    color: black;
    position: absolute;
    text-align: center;
    font-size: 30px;
    margin-top: -10px;
} */

.headerButton, .headerButton:hover, .headerButton:active, .headerButton:focus, .headerButton.active, .headerButton:checked{
    width: 180px;
    height: 50px;
    border: 2px solid #009B78;
    border-radius: 10px;
    background-color: white;
    color: #009B78;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    font-family: 'Roboto';

}

.contentButton {
    border: 1px solid #009B78;
    background: white;
    font-family: 'Roboto';
    font-size: 14px;
}

.linkButtons {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.buttonLabel {
    font-style: normal;
    font-size: 18px;
    line-height: 25px;
    color:rgba(175, 175, 175, 0.493);
}


.btnCrearExpe {
    width: 210px;
    height: 50px;
    border: 3px solid #009B78;
    border-radius: 10px;
    background-color: white;
}

.iconAddEx{
    font-size: 40px;
    color: #009B78;
    margin-left: 0px;
}

.IconX{
    color: 'grey';
    margin-left: 1100px;
}
.BtnTxtExpedition {
    
    color: #009B78;
    margin-top: 14px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    
}

.btnCrearExpe:hover {
    background-color: white;;
    color: white;
}

.btnCrearExpe:focus,
.btnCrearExpe:active {
    background-color: white;
    cursor: pointer;
    transition: .3s;
}

.btnCrearExpe:visited {
    background-color: white;
}

.divInputDestino {
    border: 1px solid #FFFFFF;
    width: 190;
    border-radius: 10px;
}

.InputDestino {
    width: 610px;
    height: 40px;
    color: #787777;
    background: #FFFFFF;
    border: 1px solid rgba(175, 175, 175, 0.493);
    border-radius: 10px;
    
}

.InputDestino::placeholder {
 font-size: 14px;
}

.divInputOrigen {
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    margin-left: 620px;
    margin-top: -57px;
}

.InputOrigen {
    width: 625px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(175, 175, 175, 0.493);
    border-radius: 10px;
}

.InputOrigen::placeholder{
 font-size: 14px;
}

.InputObservacion {
    height: 180px;
    width: 625px;
    margin-left: 620px;
    margin-top: -177px;
    left: 5;
    background: #FFFFFF;
/* GRIS CLARO CON OPACIDAD */
border: 1px solid rgba(175, 175, 175, 0.493);
border-radius: 10px;
}

.InputObservacion::placeholder {
 font-size: 14px;
}

.containerFile {
    width: 600px;

}

/*******************************************************/
/************* Input File UpLoad ***********************/
.InputText {
    text-align: center;
    color: black;
    margin-top: 40px;
    font-size: 15px;
    margin-left: 50px;

}

img.clipStyle {
    display: block;
    margin-top: -57px;
    margin-left: 20px;
}

.InputUpFile {
    position: relative;
    padding: 10px;
    width: 295px;
    background-color: white;
    border: 2px dashed #787777;
    margin-top: 20px;
    border-radius: 10px 10px;
    height: 125px;
}


    Input.InputUploadFile {
         position: absolute;
         top: 0px;
         left: 0px;
         right: 0px;
         bottom: 0px;
         opacity: 0;
    }
    .containerBtns{
         border: 1px solid black;
    }
    /**********************************************************/
    /************* Input File Download ***********************/
    .InputTextDownload {
         text-align: center;
         color: black;
         margin-top: 30px;
         font-size: 15px;
         margin-right: -85px;
    }

    img.NubeFull {
         display: block;
         display: block;
         margin-top: -57px;
         margin-left: 15px;
}

.DivDownloadFile {
    position: absolute;
    padding: 20px;
    width: 300px;
    background-color: white;
    border: 2px dashed #787777;
    margin-top: -125px;
    border-radius: 10px 10px;
    height: 125px;
    margin-left: 310px;
}

Input.InputDownloadFile {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0;
}

.InputDownloadFile:focus {
 background-color: #009B78;
}

/************* END *************************/
/*******************************************/

.styleInputForm {
    margin-top: 5px;
    width: 610px;
    height: 40px;
    background: #FFFFFF;
    /* GRIS CLARO CON OPACIDAD */
    border: 1px solid rgba(175, 175, 175, 0.493);
    border-radius: 10px;
    
}

.styleInputForm::placeholder {
 font-size: 13px;
 box-shadow: none;
}


.btnCerrar {
    border: 1px solid #EA4335;
    background-color: white;
    color: #EA4335;
    border-radius: 10px;
    width: 300px;
    height: 40px;
    margin-left: 620px;
    margin-top: -40px;
}

.btnCerrar:focus, .btnCerrar:active {
    color: white;
    width: 300px;
    height: 40px;
}

.btnCerrar:hover {
    background-color: rgba(233, 107, 107, 0.856);
}

.btnCrearExpe:focus,
.btnCrearExpe:active {
    transition: .3s;
}

.btnContinuar {
    color: white;
    border-radius: 10px;
    border: 0.5px solid #009B78;
    background-color: #009B78;
    width: 300px;
    height: 40px;
    margin-left: 940px;
    margin-top: -40px;
    font-size: 15px;
}

.btnContinuar:hover {
    background-color: #22cca4;
    color: white;
}

.pC {
    color: white;
    margin-top: 0px;
    font-size: 15px;

}

.pCa{
    color: red;
    margin-top: 0px;
    font-size: 15px;
}

@media (max-width: 567px) {

    .btnCrearExpe{
        margin-right: 2000px;
    }
    
  }
