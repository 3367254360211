.card-style{
    background: rgba(33, 168, 138, 0.21);
    align-items: center;
    height: 570px;
    border-radius: 10px;
    border: 0px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
}

.card-body-custom {
    width: 100%;
    background: url(../assets/background-circles.svg) center center no-repeat, 
    url(../assets/background-forms.svg) center bottom no-repeat;
    border: 0px;
}

.card-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 47px;
    color: #009B78;
    margin-top: 40px;
}

.card-img-top {
    display: block;
    height: 127px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 95px;
}

.card-footer {
    background: #D0EDE6;
}

.card-footer .btn {
    width: 195px;
    height: 60px;
    background: #FFFFFF;
    border: 1px solid #009B78;
    border-radius: 10px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 43px;
    color: #009B78;
    align-items: center;
}